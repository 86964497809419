import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoAttach } from "react-icons/io5";
import { inputWidth } from "./constants";
import {
  DisplayClientSideMessage,
  DeleteFiles,
} from "../../store/actions/common";
import {
  validFileExtension,
  validUploadDocExtension,
} from "../../constants/validExtensions";

function AddDocument(props) {
  const {
    text,
    extraClassName,
    selectedFile,
    selectedFiles,
    multiple,
    handleFileSelection,
    error,
  } = props;

  const fileClasses = (value) => {
    return classnames({
      badges: value,
      "badges-rounded": value,
      "left-icon": value,
      inputWidth: inputWidth,
      "badges-default": value,
      "badges-file": value,
    });
  };

  const fileSelectedHandler = (event) => {
    const validExtensions = [...validFileExtension, ...validUploadDocExtension];

    if (multiple) {
      let isAllFilesValid = true;
      let files = [];

      isAllFilesValid &&
        event.target.files.forEach((file) => {
          let fileExtension = null;
          if (file && file.name) {
            fileExtension = file.name.split(".")[1];
          } else if (file && file.UserFileName) {
            fileExtension = file.UserFileName.split(".")[1];
          }

          isAllFilesValid = validExtensions.find(
            (f) => f == (fileExtension && fileExtension.toLowerCase())
          )
            ? true
            : false;

          files.push(file);
        });

      if (isAllFilesValid) {
        if (selectedFiles && selectedFiles.length > 0) {
          files = [...selectedFiles, ...files];
        }
        handleFileSelection(files);
      } else {
        props.DisplayClientSideMessage(
          "Valid extension are " + validExtensions
        );
      }
    } else {
      const newFile = event.target.files[0];
      let fileExtension = newFile.name.split(".")[1];

      let isValid = validExtensions.find(
        (f) => f == (fileExtension && fileExtension.toLowerCase())
      )
        ? true
        : false;

      if (isValid) {
        handleFileSelection(newFile);
      } else {
        props.DisplayClientSideMessage(
          "Valid extension are " + validExtensions
        );
      }
    }
  };

  const fileRemoveHandler = async (file, index = 0) => {
    if (multiple) {
      let files = selectedFiles ? [...selectedFiles] : [];
      files.splice(index, 1);
      handleFileSelection(files);
    } else {
      handleFileSelection(null);
    }

    if (file && file.ServerFileName) {
      const params = { FileNames: [file.ServerFileName] };
      await props.DeleteFiles(params);
    }
  };

  return (
    <div className="upload-btn-wrapper" style={{ marginRight: "20px" }}>
      {(multiple || !selectedFile) && (
        <div>
          <button className={"upload-btn " + extraClassName}>
            {text}
            <input
              type="file"
              name={multiple ? "files" : "file"}
              multiple={multiple}
              onChange={fileSelectedHandler}
            />
          </button>
        </div>
      )}

      {!multiple && selectedFile && (
        <div className={fileClasses(true)}>
          <span className="addDocument-icon">
            <IoAttach />
          </span>
          <label className="addDocument-name">
            {selectedFile.name || selectedFile.UserFileName}
          </label>
          <span
            className="addDocument-icon cancel-icon"
            onClick={() => fileRemoveHandler(selectedFile)}
          >
            <AiFillCloseCircle />
          </span>
        </div>
      )}

      {multiple &&
        selectedFiles &&
        selectedFiles.map((file, index) => {
          return (
            <div className={fileClasses(true)} key={"index"}>
              <span className="addDocument-icon">
                <IoAttach />
              </span>
              <label className="addDocument-name">
                {file.name || file.UserFileName}
              </label>
              <span
                className="addDocument-icon cancel-icon"
                onClick={() => fileRemoveHandler(file, index)}
              >
                <AiFillCloseCircle />
              </span>
            </div>
          );
        })}

      {error && (
        <div className="error__message mt-sm align-center">{error}</div>
      )}
    </div>
  );
}

AddDocument.propTypes = {
  text: PropTypes.string,
  extraClassName: PropTypes.string,
  multiple: PropTypes.bool,
};
AddDocument.defaultProps = {
  text: "Add document",
  extraClassName: "addDocument",
  multiple: false,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  DisplayClientSideMessage,
  DeleteFiles,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddDocument);
